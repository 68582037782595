export const airlinesList = [
  {
    $id: "1",
    DisplayName: "Aegean Airlines (A3)",
    Code: "A3"
  },
  {
    $id: "2",
    DisplayName: "Air Lingus (EI)",
    Code: "EI"
  },
  {
    $id: "3",
    DisplayName: "Air Baltic (BT)",
    Code: "BT"
  },
  {
    $id: "4",
    DisplayName: "Air Canada (AC)",
    Code: "AC"
  },
  {
    $id: "5",
    DisplayName: "Air Europa (UX)",
    Code: "UX"
  },
  {
    $id: "6",
    DisplayName: "Air France (AF)",
    Code: "AF"
  },
  {
    $id: "7",
    DisplayName: "Air Malta (KM)",
    Code: "KM"
  },
  {
    $id: "8",
    DisplayName: "Air Serbia (JU)",
    Code: "JU"
  },
  {
    $id: "9",
    DisplayName: "American Airlines (AA)",
    Code: "AA"
  },
  {
    $id: "10",
    DisplayName: "Austrian Airlines (OS)",
    Code: "OS"
  },
  {
    $id: "11",
    DisplayName: "British Airways (BA)",
    Code: "BA"
  },
  {
    $id: "12",
    DisplayName: "Brussels Airlines (SN)",
    Code: "SN"
  },
  {
    $id: "13",
    DisplayName: "Cathay Pacific (CX)",
    Code: "CX"
  },
  {
    $id: "14",
    DisplayName: "China Airlines (CI)",
    Code: "CI"
  },
  {
    $id: "15",
    DisplayName: "China Eastern (MU)",
    Code: "MU"
  },
  {
    $id: "16",
    DisplayName: "China Southern (CZ)",
    Code: "CZ"
  },
  {
    $id: "17",
    DisplayName: "Croatia Airlines (OU)",
    Code: "OU"
  },
  {
    $id: "18",
    DisplayName: "Delta Airlines (DL)",
    Code: "DL"
  },
  {
    $id: "19",
    DisplayName: "Emirates (EK)",
    Code: "EK"
  },
  {
    $id: "20",
    DisplayName: "Ethiopian Airlines (ET)",
    Code: "ET"
  },
  {
    $id: "21",
    DisplayName: "Etihad Airways (EY)",
    Code: "EY"
  },
  {
    $id: "22",
    DisplayName: "Eva Air (BR)",
    Code: "BR"
  },
  {
    $id: "23",
    DisplayName: "Finnair (AY)",
    Code: "AY"
  },
  {
    $id: "24",
    DisplayName: "Garuda Indonesia (GA)",
    Code: "GA"
  },
  {
    $id: "25",
    DisplayName: "Georgian Airways (A9)",
    Code: "A9"
  },
  {
    $id: "26",
    DisplayName: "Iberia (IB)",
    Code: "IB"
  },
  {
    $id: "27",
    DisplayName: "Icelandair (FI)",
    Code: "FI"
  },
  {
    $id: "28",
    DisplayName: "ITA Airways (AZ)",
    Code: "AZ"
  },
  {
    $id: "29",
    DisplayName: "Japan Airlines (JL)",
    Code: "JL"
  },
  {
    $id: "30",
    DisplayName: "Kenya Airways (KQ)",
    Code: "KQ"
  },
  {
    $id: "31",
    DisplayName: "KLM (KL)",
    Code: "KL"
  },
  {
    $id: "32",
    DisplayName: "Korean Air (KE)",
    Code: "KE"
  },
  {
    $id: "33",
    DisplayName: "LOT Polish Airlines (LO)",
    Code: "LO"
  },
  {
    $id: "34",
    DisplayName: "Lufthansa (LH)",
    Code: "LH"
  },
  {
    $id: "35",
    DisplayName: "Qatar Airways (QR)",
    Code: "QR"
  },
  {
    $id: "36",
    DisplayName: "Royal Jordanian (RJ)",
    Code: "RJ"
  },
  {
    $id: "37",
    DisplayName: "SAS Scandinavian Airlines (SK)",
    Code: "SK"
  },
  {
    $id: "38",
    DisplayName: "Singapore Airlines (SQ)",
    Code: "SQ"
  },
  {
    $id: "39",
    DisplayName: "Swiss International Airlines (LX)",
    Code: "LX"
  },
  {
    $id: "40",
    DisplayName: "TAP Portugal (TP)",
    Code: "TP"
  },
  {
    $id: "41",
    DisplayName: "Thai Airways (TG)",
    Code: "TG"
  },
  {
    $id: "42",
    DisplayName: "Turkish Airlines (TK)",
    Code: "TK"
  },
  {
    $id: "43",
    DisplayName: "United Airlines (UA)",
    Code: "UA"
  }
];
